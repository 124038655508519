import { CompanyAccessLevel } from '@/auth';
import { inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Pipe({
	standalone: true,
	name: 'accessLevelToString',
})
@Injectable({ providedIn: 'root' })
export class AccessLevelToStringPipe implements PipeTransform {
	private translocoService = inject(TranslocoService);

	transform(value: CompanyAccessLevel | null, ...args: unknown[]): string {
		return value ? this.translocoService.translate(`company.access.${value?.toString()}`) || value?.toString() : '';
	}
}
